//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import { mapGetters, mapActions } from 'vuex'
import productQuantity from './product-quantity.vue';

export default {
  components: { productQuantity },
    name: 'cart',
    computed: {
        ...mapGetters('shopify', [
            'id',
            'lineItems',
            'price',
            'url',
            'showCart'
        ]),
        quantity(){
            let qty = 0;
            // quantity in cart based on individual item quantity
            this.lineItems.map(i => qty += parseInt(i.quantity))
            return qty
        },
        filteredItems(){
            // basically, if an item is added and then edited on shopify so the variant doesn't exist anymore,
            // we filter it out and remove it from cart
            const newItems = this.lineItems.filter(i => {
                if(!i.variant) this.removeFromCart(i.id);
                return !!i.variant;
            })
            return newItems
        }
    },
    data(){
      return {
        enable_shop: true
      }
    },
    methods: {
        ...mapActions('shopify', [
            'show_checkout',
            'hide_checkout',
            'update_item_quantity',
            'remove_from_cart',
            'init_cart'
        ]),
        toggleCart(){
            this.showCart ? this.hide_checkout() : this.show_checkout()  ;
        },
        subtitle(item){

            // this generates variant subtitles with selected options in
            if(item.variant.selectedOptions.length === 1 && item.variant.selectedOptions[0].value === 'Default Title') return

            const opts = [];
            item.variant.selectedOptions.map(i => {
                opts.push(i.name + ': ' + i.value)
            })

            return opts.join(' / ')
        },
        inc(id){
          // inc qty
          const item = this.lineItems.find(i => i.id === id);
          if(!item) return;
          const newQuantity = item.quantity + 1;
          this.update_item_quantity({lineItemId: id, quantity: newQuantity});
        },
        dec(id){
          // dec qty
          const item = this.lineItems.find(i => i.id === id);
          if(!item) return;
          const newQuantity = item.quantity - 1;
          this.update_item_quantity({lineItemId: id, quantity: newQuantity});
        },
        remove(id){
          // remove from cart
          this.remove_from_cart(id);
        }
    },
    mounted(){
      this.enable_shop = process.env.NUXT_DISABLE_SHOP == "false";
      this.init_cart();
    }
}
