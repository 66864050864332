//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
  name: "navigationDefault", 
  data() { 
    return{
      show_search: false
    }
  },
  computed: {
    search_css(){
      return this.show_search ? 'visible' : 'hidden';
    },
	  menu() {
		  return this.$store.state.global.content.navigationMain;
	  },
	  right_menu() {
		  return this.$store.state.global.content.navigationMainRight;
	  }
  }
};
