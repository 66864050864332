//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Signupbar from '../components/signupbar.vue'

	import { mapState } from 'vuex'
	import { mapMutations } from 'vuex' 
    import NavigationFooter from '../components/navigation/navigationFooter.vue';
    import Signup from '../components/signup.vue'
import NavigationDefault from '../components/navigation/navigationDefault.vue';
import NavigationHomeDefault from '../components/navigation/navigationHomeDefault.vue';

	export default {
    name: "layoutDefault",
    created() {
        this.$nuxt.$on('update_page', ($event) => this.updateNav($event))
    },
    methods: {
        ...mapMutations({
            setOffscreenState: "global/setOffscreenState"
        }),
        openSubscribe(){
            this.$refs.signup.toggleSignup();
        },
        updateNav($page){

            console.log($page);

            if ($page > 0){
                this.$refs.navdefault.$el.classList.add("hidden");
                this.$refs.navdefault.$el.classList.remove("visible");
                this.$refs.navother.$el.classList.add("visible");
                this.$refs.navother.$el.classList.remove("hidden");
            }else{
                this.$refs.navdefault.$el.classList.add("visible");
                this.$refs.navother.$el.classList.add("hidden");
                this.$refs.navdefault.$el.classList.remove("hidden");
                this.$refs.navother.$el.classList.remove("visible");

            }
 
        }, 
    },
    data() {
        return {
            enable_shop: process.env.NUXT_ENV_DISABLE_SHOP == "false"
        };
    },
    computed: {
        ...mapState({
            navigationFooter(state) {
                return state.global.content.navigationFooter;
            },
            navigationSidebar(state) {
                return state.global.content.navigationSidebar;
            },
            navigationMain(state) {
                return state.global.content.navigationMain;
            },
            navigationMainRight(state) {
                return state.global.content.navigationMainRight;
            },
            offscreenState(state) {
                return state.global.offscreenState;
            }
        })
    },async fetch() { 


let endpoint = 'api/query';

if (!process.server)
{
	endpoint = '/.netlify/functions/content';
}


	 await this.$axios.$post(endpoint, {
		query: "site",
		select: {
			title: "site.title",
			instagramhandle: "site.instagramhandle",
			getintouch: "site.getintouch",		
			signupurl: "site.signupurl",	
            subscribe_triggerText: true,
            subscribe_secondaryText: true,
			sponsors: {"query": "site.sponsors.toFiles",
			"select" : {
				"url": true,
				"name": true }
			},
			navigationMain: "site.navigation_main.toNavigationArray",
            navigationMainRight: "site.navigation_main_right.toNavigationArray",
			navigationFooter: "site.navigation_footer.toNavigationArray",
            signup_headline: true,
            signup_heading: true,
            signup_intro: true,
            ts_cs_text: true,
            ts_cs_link: true,
            singup_consent: true,
            signup_button_text: true,
            signup_confirmation_text: true,
            facebook_handle: true,
            instagram_handle: true,
            twitter_handle: true,
            tiktok_handle: true

		}
	}).then(resp =>
	 {				
        // This needs adding to the other layouts too (when hooked up to Kirby!) 
		this.$store.commit('global/setGlobalContent', resp.result)
	}).catch(e =>{
			console.log("navigation bar error", e);
	}) 


},

    async asyncData({ store, params, $axios }) {
        let endpoint = "api/query";
        console.log("axios endpoint", endpoint);
        await $axios.$post(endpoint, {
            query: "site",
            select: {
                title: "site.title",
                instagramhandle: "site.instagramhandle",
                getintouch: "site.getintouch",
                signupurl: "site.signupurl",
                sponsors: { "query": "site.sponsors.toFiles", "select": {
                        "url": true,
                        "name": true
                    }
                },
                navigationMain: "site.navigation_main.toNavigationArray",
                navigationMainRight: "site.navigation_main_right.toNavigationArray",
                navigationFooter: "site.navigation_footer.toNavigationArray",
                navigationSidebar: "site.navigation_sidebar.toNavigationArray",
                signup_headline: true,
                signup_heading: true,
                signup_intro: true,
                ts_cs_text: true,
                ts_cs_link: true,
                singup_consent: true,
                signup_button_text: true,
                signup_confirmation_text: true,
                facebook_handle: true,
                instagram_handle: true,
                twitter_handle: true,
                tiktok_handle: true
            }
        }).then(resp => {
            $store.commit("global/setGlobalContent", resp.result);
        }).catch(e => {
            console.log("this un");
            console.log("navigation bar error", e);
        });
    },
    components: { NavigationFooter, Signup, NavigationDefault, NavigationHomeDefault, Signupbar }
}

