import axios from 'axios';

export const state = () => ({
  directory: []
})

export const mutations = {
  SET_DIRECTORY(state, dir){
    //console.log("COMMITTING", dir);
    state.directory = dir
  }
}

export const actions = {
  async nuxtServerInit ({ commit, state }, { req }) {
    
    

    await this.$axios.$post('api/query', {
      query: "site.index",
      select: {
        key: "page.id",
        head: "page.title.toSeoHelper",
        breadcrumb: "page.title.toCrumbs",
        hero : "page.title.toHeroHelper",
        blocks: "page.blocks.toBlocksHelper",        
      }
    }).then(resp =>
      {
        //console.log("building...");

        let data = resp.result.data;

        //console.log("found pages:", data.length);
        let directory = [];
        for (let i = 0; i < data.length; i++)
        {
          let key = data[i].key;
           directory[key] = data[i];

           
        }
        

        commit('SET_DIRECTORY', directory);    		
      }
    ).catch(function (error) {
        if (error.response) {
          // Request made and server responded
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message);
        }
    });

    
  }
}