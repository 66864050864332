//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

const PROMO_BAR = "hide_promo_bar";
import { mapState } from 'vuex';

export default {
  name: "signupBar",
  data() {
    return {
      hide_promo_bar: false,
    };
  },
  mounted() {
    if (sessionStorage.getItem(PROMO_BAR)) {
      this.hide_promo_bar = true;
    }
  },
  methods: {
    dismissPromo() {
      let $promo = this.$refs.promo_bar;
      this.hide_promo_bar = true;
      $promo.classList.add("remove_bar");
      sessionStorage.setItem(PROMO_BAR, 1);
    },
  },
  computed: {
    ...mapState({
        subscribeBar(state) {
            return state.global.content;
        }		
    }),
  },
};
