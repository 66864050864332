//
//
//
//
//
//
//
//

	import { mapState } from 'vuex'
  export default {
    props: ['error'],
    layout: 'error', // you can set a custom layout for the error page
    data()
    {
      return {
        hero: 
        {            
			      additionalClasses: "bg-gradient-pink-white pb-200px",
            h1: this.error.statusCode,
            intro: this.error.message,
            isError: true
        }
      }
        
    } 
  }
