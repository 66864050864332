//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import NavigationFooter from '../components/navigation/navigationFooter.vue'

	import { mapState } from 'vuex'
	import { mapMutations } from 'vuex'
import OtherBg from '../components/otherBg.vue'
import NavigationDefault from '../components/navigation/navigationDefault.vue'

	export default {
		name: 'layoutOther',
		methods: {
			...mapMutations({
				setOffscreenState: 'global/setOffscreenState'
			}),
			updateFill(val){
				console.log(val);
				this.bgfill = val;
			}
		},
		components:{
    NavigationFooter,
    OtherBg,
    NavigationDefault
},
  created() {
    this.$nuxt.$on('changeColor', ($event) => this.updateFill($event))
  },
		computed: {
			...mapState({
				navigationFooter(state)
				{					
					return state.global.content.navigationFooter
				},
				navigationMain(state) {
					return state.global.content.navigationMain
				},
				navigationMainRight(state) {
					return state.global.content.navigationMainRight;
				},
				offscreenState(state) {
					return state.global.offscreenState
				}
			})
		},
		data(){
			return {
				bgfill: ""
			}
		}, 
		async fetch() { 
			let endpoint = 'api/query';

if (!process.server)
{
	endpoint = '/.netlify/functions/content';
}

			 	await this.$axios.$post(endpoint, {
					query: "site",
					select: {
						title: "site.title",
						subscribe_triggerText: true,
						subscribe_secondaryText: true,
						instagramhandle: "site.instagramhandle",
						getintouch: "site.getintouch",		
						signupurl: "site.signupurl",				
						sponsors: {"query": "site.sponsors.toFiles",
						"select" : {
							"url": true,
							"name": true }
						},
						navigationMain: "site.navigation_main.toNavigationArray",
						navigationMainRight: "site.navigation_main_right.toNavigationArray",
						navigationFooter: "site.navigation_footer.toNavigationArray",
						signup_headline: true,
						signup_heading: true,
						signup_intro: true,
						ts_cs_text: true,
						ts_cs_link: true,
						singup_consent: true,
						signup_button_text: true,
						signup_confirmation_text: true,
						facebook_handle: true,
						instagram_handle: true,
						twitter_handle: true,
						tiktok_handle: true
					}
				}).then(resp =>
     			{				
					this.$store.commit('global/setGlobalContent', resp.result)
				}).catch(e =>{
						console.log("navigation bar error", e);
				}) 


		}
	}

