//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Signupbar from '../signupbar.vue';

export default {
    name: "navigationFooter",
	methods:{
		openSubscribe(){
			this.$emit('openSubscribe');
		}
	},
    computed: {
        footer() {
            return {
                sponsors: this.$store.state.global.content.sponsors,
                instagramhandle: this.$store.state.global.content.instagramhandle,
                getintouch: this.$store.state.global.content.getintouch,
                signupurl: this.$store.state.global.content.signupurl,
				content: this.$store.state.global.content
            };
        },
        menu() {
            return this.$store.state.global.content.navigationFooter;
        }
    },
    components: { Signupbar }
}

