import Vue from 'vue'

Vue.mixin({
     data() 
     {
         return {
          
        }
     }
  })
