//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//



        
export default
{
    name: "signup",
    data(){
        return {
            show_signup: false,
            show_form: true,
            mail_error: "",
            loading: false,
            content: {}
        }
    }, 
    methods:{
        toggleSignup(){ 
            this.show_signup = !this.show_signup;
        },
        submitEmail(email, name, dob) {
            let self = this;
            this.loading = true;
            fetch('/.netlify/functions/subscribe', {
                method: 'POST',
                body: JSON.stringify({
                email: email,
                dob: dob,
                name: name
            })
            }).then(function(response) {
                return response.json();
            }).then(function(data) {

                if (data.emailAdded){
                    self.show_form = false;
                } else{
                    self.mail_error = data.error;
                }
                
                self.loading = false;
            }).catch((error) => {
                
                self.loading = false;
            });

        }
    },
    mounted(){ 

        this.content = this.$store.state.global.content;


        let self = this;
        this.$refs.newsletter.addEventListener("submit", function(e) {
            e.preventDefault();
            let email = document.getElementById('inputEmail').value;
            let name = document.getElementById('inputName').value;
            let dob = document.getElementById('inputDoB').value;
            self.submitEmail(email, name, dob)
        });
    }
}

