export const state = () => ({
	showCart: false,
	checkout: {
		id: null,
		lineItems: [],
		url: null,
		price: null
	},
	product: false,
	selectedVariant: null
})

export const getters = {
	id: (state) => {
		return state.checkout.id
	},
	lineItems: (state) => {
		return state.checkout.lineItems
	},
	price: (state) => {
		return state.checkout.price
	},
	url: (state) => {
		return state.checkout.url
	},
	showCart: (state) => {
		return state.showCart
	},
	product: (state) => {
		return state.product
	},
	selectedVariant: (state) => {
		// if no product selected
		if(!state.product) return null;
		// if no variants (one variant, default title), return default
		if(state.product.variants.length === 1 && state.product.variants[0].title === 'Default Title') return state.product.variants[0]
		// search for selected
		return state.product.variants.find(i => i.id === state.selectedVariant);
	}
}

export const mutations = {
	checkout_data(state, data) {
		state.checkout = { ...state.data, ...data }
	},
	show_checkout(state, data) {
		state.showCart = data
	},
	set_product(state, data) {
		state.product = data
	},
	set_selected_variant(state, data) {
		state.selectedVariant = data
	},
}

export const actions = {
	update_checkout({ commit, dispatch }, data) {

		console.log('updating checkout');

		const newData = {
			id: data.id,
			lineItems: data.lineItems,
			url: data.webUrl,
			price: data.totalPrice
		}

		commit('checkout_data', newData)
		dispatch('set_saved_cart_id', data.id)
	},

	show_checkout({ commit, dispatch }) {
		commit('show_checkout', true)
	},

	hide_checkout({ commit, dispatch }) {
		commit('show_checkout', false)
	},

	/**
	 * Product actions
	 * 
	 */

	// load product by handle
	set_product_handle({commit, dispatch}, productHandle){
		return new Promise((res, rej) => {
			this.$shopify.product.fetchByHandle(productHandle)
				.then(product => {
					commit('set_product', {...product})
					res({...product})
				})
				.catch(error => {
					console.log('error', error);
					rej()
				})
		})
	},

	// set via id
	set_product_id({commit, dispatch}, productId){
		return new Promise((res, rej) => {
			console.log('product Id', productId)
			this.$shopify.product.fetch(productId)
				.then(product => {
					const newProduct = {...product}
					newProduct.images = newProduct.images.map(i => ({...i}));
					console.log('setting product', newProduct)
					commit('set_product', newProduct)
					res(product)
				})
				.catch(error => {
					console.log('error', error);
					rej()
				})
		})
	},

	set_selected_variant({commit, dispatch}, variantId){
		return commit('set_selected_variant', variantId)
	},

	/**
	 * Cart actions
	 * 
	 */

	async init_cart({commit, dispatch}){
		const id = await dispatch('get_saved_cart_id');
		await dispatch('load_saved_cart', id).catch(error => dispatch('create_new_checkout'));
	},

	get_saved_cart_id({commit, dispatch}){
		return window.localStorage.getItem('checkoutId')
	},

	set_saved_cart_id({commit, dispatch}, checkoutId){
        window.localStorage.setItem('checkoutId', checkoutId);
	},

	// load cart from shopify
	load_saved_cart({commit, dispatch}, checkoutId){
		return new Promise((res, rej) => {
			if(!checkoutId) return rej('no cart id');
			this.$shopify.checkout.fetch(checkoutId)
				.then(checkout => {
					dispatch('update_checkout', checkout)
					res(checkout)
				})
				.catch(err => rej('error fetching cart'))
		})
	},

	create_new_checkout({commit, dispatch}){
		this.$shopify.checkout.create().then((checkout) => {
			dispatch('update_checkout', checkout)
		});
	},

	add_to_cart({commit, dispatch, state}, {variantId, quantity}){
		return new Promise((res, rej) => {

			if(!quantity) quantity = 1;

			const lineItemsToAdd = [
				{
					variantId,
					quantity
				}
			];

			// Add an item to the checkout
			this.$shopify.checkout.addLineItems(state.checkout.id, lineItemsToAdd).then(async checkout => {
				await dispatch('update_checkout', checkout)
				res(checkout);
			})
			.catch(rej);
		})
	},

	update_item_quantity({commit, dispatch, state}, {lineItemId, quantity}){

		console.log('item qty', quantity)
		return new Promise((res, rej) => {
			this.$shopify.checkout.updateLineItems(state.checkout.id, [{id: lineItemId, quantity}]).then(async (checkout) => {
				await dispatch('update_checkout', checkout)
				res(checkout)
			})
			.catch(rej)
		})
	},

	remove_from_cart({commit, dispatch, state}, lineItemId){
		return new Promise((res, rej) => {
			this.$shopify.checkout.removeLineItems(state.checkout.id, [lineItemId]).then(async (checkout) => {
				await dispatch('update_checkout', checkout)
				res(checkout)
			})
			.catch(rej)
		})
	}

}
