export const state = () => ({
	content: {},
	children: {},
	filters: {},
	related_posts: {}
})

export const mutations = {
	setPageContent(state, data) {
		state.content = data;
		console.log("STATE", data);
	},
	setPageFilters(state, data) {
		state.filters = data;
		//console.log("STATE", data);
	},
	setPageChildren(state, data) {
		state.children[data.key] = data.value
	},
	setRelatedPosts(state, data) {
		state.related_posts = data
	},
}

export const getters = {
	content: state => {
		return Object.keys(state.content).length ? state.content : {}
	}
}
