//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//




/**
 * width: 100%;
    border-width: 1px;
    --tw-border-opacity: 1;
    border-color: rgba(136, 132, 132, var(--tw-border-opacity));
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-right: 2.5rem;
 */

    export default {
        name: 'product-quantity',
        props: {
            value: {
                type: Number,
                required: true
            }
        },
        methods: {
            inc(){
                this.$emit('input', this.value + 1)
            },
            dec(){
                if(this.value <= 1) return;
                this.$emit('input', this.value - 1)
            }
        }
    }
