import { render, staticRenderFns } from "./default.vue?vue&type=template&id=518f507e&"
import script from "./default.vue?vue&type=script&lang=js&"
export * from "./default.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Signupbar: require('/opt/build/repo/frontend/components/signupbar.vue').default,NavigationHomeDefault: require('/opt/build/repo/frontend/components/navigation/navigationHomeDefault.vue').default,NavigationDefault: require('/opt/build/repo/frontend/components/navigation/navigationDefault.vue').default,Signup: require('/opt/build/repo/frontend/components/signup.vue').default,NavigationFooter: require('/opt/build/repo/frontend/components/navigation/navigationFooter.vue').default})
